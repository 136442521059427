import { useEffect } from "react"
import { styled } from "@mui/material"
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFilterAtom } from "../../hooks/FilterHooks"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip, useTheme, Zoom } from "@mui/material"

const activityDateTooltipContent = "Your emission data is being charted by the date the last activity in your itinerary occurred. Toggle to chart by Data Received Date."
const dataReceivedDateToolipContent = "Your emission data is being charted by the date your data was received via the BlueHalo API. We invoice based on these dates. Toggle to chart by Activity Date."

const ActivityDateSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
	'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: '#1890ff',
				...theme.applyStyles('dark', {
						backgroundColor: '#177ddc',
					}),
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
		...theme.applyStyles('dark', {
			backgroundColor: 'rgba(255,255,255,.35)',
		}),
	},
}))

export default function ToggleButton() {
	const [filterData, setFilterData] = useFilterAtom()
	const theme = useTheme()
	const tooltipContent = <p style={{textAlign: "left"}}>{filterData.useActivityDate ? activityDateTooltipContent : dataReceivedDateToolipContent}</p>
	const toggleUseActivityDate = () => {
		setFilterData({...filterData, useActivityDate: !filterData.useActivityDate})
	}

	useEffect(() => {
		if(filterData.useActivityDate === undefined){
			setFilterData({...filterData, useActivityDate: true})
		}
	})

	return (
		<Stack direction="row" spacing={1} sx={{ alignItems: 'center', marginRight: '10px' }}>
			<Typography>Use Activity Date</Typography>
			<ActivityDateSwitch defaultChecked inputProps={{ 'aria-label': 'select sort by date type' }} onChange={() => toggleUseActivityDate()} checked={filterData.useActivityDate}/>
			<Tooltip TransitionComponent={Zoom} title={tooltipContent}>
				<HelpOutlineIcon fontSize="small"/>
			</Tooltip>
		</Stack>
	)
}
