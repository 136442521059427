export const BACKEND_BASE_URL = window._env_.REACT_APP_BACKEND_API_URL
export const BACKEND_BASE_API_KEY = window._env_.REACT_APP_BACKEND_API_KEY ?? null

export const LATEST_API_VERSION = "2024-09-17"
export const API_VERSION_PARAM = `?api-version=${LATEST_API_VERSION}`

// Cache warmup endpoint
export const CACHE_WARMUP_ENDPOINT = "/emissions/warmup"

//GROUPBY REPLACEMENT ENDPOINTS
export const EMISSIONS_AIRBYCLASS_ENDPOINT = "/emissions/air/byTravelClass"
export const EMISSIONS_ACCOMMBYSTAR_ENDPOINT = "/emissions/accommodation/byStarRating"
export const EMISSIONS_ACCOMMBYCOUNTRY_ENDPOINT = "/emissions/accommodation/byCountry"
export const EMISSIONS_ROADBYRENTALDAYS_ENDPOINT = "/emissions/road/byRentalDays"
export const EMISSIONS_ROADBYACRISS_ENDPOINT = "/emissions/road/byAcrissCode"

export const EMISSIONS_OVERVIEW_ENDPOINT = "/emissions/overview"
export const EMISSIONS_LOG_ENDPOINT = "/emissions/log"
export const EMISSIONS_BY_COST_CENTRE_ENDPOINT = "/emissions/byCostCentre"
export const ACTIVITY_TOTAL_ENDPOINT = "/activitytotals"

export const RETIREMENT_OFFSET_ENDPOINT = `/retirements/getByDate${API_VERSION_PARAM}`

export const RETIREMENTS_CERTIFICATES_ENDPOINT = '/retirements/certificates'
export const RETIREMENTS_BY_COUNTRY_ENDPOINT = `/retirements/getRetiredOffsetsByCountry${API_VERSION_PARAM}`
export const RETIREMENTS_BY_PROJECT_TYPE_ENDPOINT = `/retirements/getRetiredOffsetsByProjectType${API_VERSION_PARAM}`

// User modes
export const CUSTOMER_MODE_ENDPOINT = `/user/mode`

export const ACTIVITY_FILTERS = [
	{ id: "accom", label: "Accommodation" },
	{ id: "air", label: "Air" },
	{ id: "road", label: "Road" },
	{ id: "rail", label: "Rail" },
	{ id: "sea", label: "Sea" },
]

export const ACTIVITY_CHART_CONFIG = {
	air: { priority: 5, color: "#EA9280" },
	accommodation: { priority: 4, color: "#AA99EC" },
	road: { priority: 3, color: "#5EB0EF" },
	rail: { priority: 2, color: "#53B9AB" },
	sea: { priority: 1, color: "#FA934E" },
}
