import { useFilterAtomValue } from "../../hooks/FilterHooks"
import { usePartnerAtomValue } from "../../hooks/PartnerHooks"
import { LATEST_API_VERSION } from "../../util/Constants"

export const setQueryParameters = (method = "GET") => {
	const { dateFrom, dateTo, useActivityDate } = useFilterAtomValue()
	const partnerVal = usePartnerAtomValue()

	if (partnerVal.includes("Clear"))
		return {
			dateFrom: dateFrom,
			dateTo: dateTo,
			useActivityDate: useActivityDate,
			"api-version": LATEST_API_VERSION
		}

	const hasPartners = Object.entries(partnerVal).length
	const response = {
		dateFrom: dateFrom,
		dateTo: dateTo,
		useActivityDate: useActivityDate,
		"api-version": LATEST_API_VERSION
	}

	if (hasPartners) {
		if (method == "POST") {
			response.partnerCodes = partnerVal.split(",")
		} else {
			response.partners = partnerVal
		}
	}

	return response
}
