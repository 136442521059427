import Certificates from "../pages/Certificates"
import Dashboards from "../pages/Dashboards"
import Portfolio from "../pages/Portfolio"
import Emissions from "../pages/Reports/Emissions"
import Retirements from "../pages/Reports/Retirements"
import NotFound from "../pages/NotFound"

import EmissionsLog from "../pages/Reports/EmissionsLog"

export const routeConfig = [
	{ path: "/", element: <Dashboards />, showHeader: true },
	{ path: "/reports/retirements", element: <Retirements />, showHeader: true },
	{ path: "/reports/emissions", element: <Emissions />, showHeader: true },
	{ path: "/reports/emissions-log", element: <EmissionsLog />, showHeader: true },
	{ path: "/portfolio", element: <Portfolio /> },
	{ path: "/certificates", element: <Certificates /> },
	{ path: "*", element: <NotFound /> },
]
