import * as React from "react"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { PickersDay } from "@mui/x-date-pickers/PickersDay"
import { useState } from "react"
import { Box, ClickAwayListener, Paper, Typography, styled } from "@mui/material"
import moment from "moment"
import { useEffect } from "react"
import styles from "./index.module.scss"

const DayPickerContainer = styled(Box)(() => ({
	"& .Mui-selected": {
		"&.between": {
			":focus": {
				background: "rgba(92, 203, 242, 0.4)",
				color: "black",
			},

			background: "rgba(92, 203, 242, 0.4)",
			color: "black",
		},

		"&.not-between": {
			":focus": {
				background: "inherit",
				color: "black",
			},
			background: "inherit",
			color: "black",
		},
	},
}))

export default function CustomDateRangePicker({ closeCalendar, dates, open, updateDate = null, fromKey = "dateFrom", toKey = "dateTo" }) {
	const [view, setView] = useState(fromKey)
	const [value, setValue] = useState(dates)
	const [valueOverride, setValueOverride] = useState(null)
	const [intervalView, setIntervalView] = useState("day")

	const maxRange = 13

	const onClickAway = () => {
		closeCalendar()
		setView(fromKey)
		setIntervalView("day")
		setValueOverride(null)
	}

	const withinDateRange = (fromDate, toDate) => {
		return moment(toDate).diff(moment(fromDate), 'months', true) <= maxRange
	}

	const setDate = (val, newDate) => {
		const isFromKey = val === fromKey
		let newValue = value
		newValue[val] = newDate.format("YYYY-MM-DD")
		if(!withinDateRange(newValue[fromKey], newValue[toKey])) {
			newValue[toKey] = moment(newDate).add(maxRange, 'M').format('YYYY-MM-DD')
		}
		setValue({ ...newValue })
		setView(isFromKey ? toKey : fromKey)
		setIntervalView("day")
		setValueOverride(null)

		if (updateDate) updateDate({ ...newValue, daysBetween: null, selectionString: null })
		if (!isFromKey) onClickAway()
	}

	useEffect(() => {
		setValue(dates)
	}, [dates])

	if (!open) return null

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<Paper className="p-2-theme" style={{ width: "350px", zIndex: 1000 }}>
				{[fromKey, toKey].map((val) => {
					const isFrom = val === fromKey

					const renderDay = (day, selected, props) => {
						let append = ""
						const fromDate = moment(value[fromKey])

						if(!withinDateRange(value[fromKey], value[toKey], maxRange)) {
							value[toKey] = moment(value[fromKey]).add(maxRange, 'M').format('YYYY-MM-DD')
						}

						const toDate = moment(value[toKey])
						const isBetweenSelected = day <= toDate && day >= fromDate

						if (isBetweenSelected) {
							if (day.format("YYYY-MM-DD") == toDate.format("YYYY-MM-DD")) append = styles["picker-day-between-last"]
							if (day.format("YYYY-MM-DD") == fromDate.format("YYYY-MM-DD")) append = styles["picker-day-between-first"]
						}

						return (
							<DayPickerContainer
								onClick={() => {
									setDate(val, day)
								}}
							>
								<PickersDay className={isBetweenSelected ? `between ${styles["picker-day-between"]} ${append}` : "not-between"} {...props} />
							</DayPickerContainer>
						)
					}

					const updateIntervalView = () => {
						if (intervalView === "day") setIntervalView("year")
						if (intervalView === "month") setIntervalView("day")
						if (intervalView === "year") setIntervalView("month")
					}

					return (
						<>
							{view === val && (
								<Box data-testid={"static-date-picker"}>
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<StaticDatePicker
											onViewChange={() => updateIntervalView()}
											onChange={(newValue) => {
												updateIntervalView()
												setValueOverride(newValue)
											}}
											view={intervalView}
											views={["day", "month", "year"]}
											renderDay={renderDay}
											className="m-0"
											displayStaticWrapperAs="desktop"
											value={valueOverride ? valueOverride : value[val]}
											minDate={!isFrom ? moment(value[fromKey]).add(1, 'D').format('YYYY-MM-DD') : "2014-01-01"}
											maxDate={!isFrom ? moment(value[fromKey]).add(13, 'M').format('YYYY-MM-DD') : moment(new Date())}
											ToolbarComponent={() => (
												<Box className="txt-align-center" width="100%">
													<Typography
														className={`txt-transform-capitalize cursor-pointer ${isFrom ? "txt-main" : "txt-secondary opacity-60"}`}
														onClick={() => setView(fromKey)}
														data-testId={"from-label"}
														variant="p"
													>
														From
													</Typography>
													<Box className="border-l-1 border-col-light display-inline m-tb-0 m-lr-20" height={20} width={1}></Box>
													<Typography
														className={`txt-transform-capitalize cursor-pointer ${!isFrom ? "txt-main" : "txt-secondary opacity-60"}`}
														onClick={() => setView(toKey)}
														data-testId={"to-label"}
														variant="p"
													>
														To
													</Typography>
												</Box>
											)}
											showToolbar={true}
										/>
									</LocalizationProvider>
								</Box>
							)}
						</>
					)
				})}
			</Paper>
		</ClickAwayListener>
	)
}
