
export const transformCostCentresToCSV = (data) => {

	// Build header row
	let headerRow = []
	let maxCCDepth = 1
	// Determine how many cost centre headings we need
	for (let costCentreGroup of data) {
		maxCCDepth = Math.max(maxCCDepth, costCentreGroup.costCentres.length)
	}

	// Add cost centre headings
	for (let i = 1; i <= maxCCDepth; i++) {
		headerRow = [...headerRow, `Cost Centre Name (Level ${i})`, `Cost Centre Code (Level ${i})`]
	}

	// Add date headings and total heading
	headerRow = [...headerRow, ...data[0].dateGroups.map(g => g.date), "total"]

	// Build data rows
	let csvArray = []

	for (const { costCentres, dateGroups, totalEmissions } of data) {
		let row = []

		// Populate cost centre columns
		for (const { name, code } of costCentres) {
			row.push(name ? name : "", code ? code : "")
		}

		for (let i = costCentres.length; i < maxCCDepth; i++) {
			row.push("", "")
		}

		// Populate emission columns
		for (const { date, emissions } of dateGroups) {
			row.push(emissions)
		}
		row.push(totalEmissions)

		csvArray.push(row.join(","))
	}

	return [headerRow.join(","), ...csvArray].join("\n")
}

export const transformEmissionsLogToCSV = (data) => {
	const logData = data.data.logs
	let modeObjects = {}
	let modeColumns = {}
	let modes = []

	// Column headings for the CSV taken from the fields in the log data.
	// If a new field is added to the emissions log data, it will show up 
	// as a new column in the emissions log csv without having to make any 
	// changes here.

	for (const row of logData) {
		const mode = row.mode

		// If this is the first row for this mode
		if (!modes.includes(mode)) 
		{
			// Build list of unique modes
			modes.push(mode)
			// Initialize the list of rows and columns for this mode
			modeObjects[mode] = []
			modeColumns[mode] = []
		}

		// Standard fields across all modes
		const commonKeys = Object.keys(row).filter((x) => x !== "metadata")

		// Fields specific to mode
		const metadataKeys = Object.keys(row.metadata).filter((x) => x !== "costCentre")

		// Initialise row for csv
		let rowObj = {}

		// Top level data
		for (const key of commonKeys) {
			if (!modeColumns[mode].includes(key)) modeColumns[mode].push(key)
			rowObj[key] = row[key]
		}

		// Metadata
		for (const key of metadataKeys) {
			if (!modeColumns[mode].includes(key)) modeColumns[mode].push(key)
			rowObj[key] = row.metadata[key]
		}

		modeObjects[mode].push(rowObj)
	}

	let csvPerMode = {}
	for (const mode of modes) {
		let csvForMode = [modeColumns[mode].join(",")]

		for (const obj of modeObjects[mode]) {
			let objArray = []

			for (const column of modeColumns[mode]) {
				objArray.push(obj[column] ?? null)
			}

			csvForMode.push(objArray.join(","))
		}

		csvPerMode[mode] = csvForMode.join("\n")
	}

	return csvPerMode
}
