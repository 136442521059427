import { Button, CircularProgress } from "@mui/material"
import { ErrorHandling } from "../Error"

export default function ExportButton({ data, transformData, fileName, fileType = "text/plain", fileExtension = "txt", loading = false, customDownloadFile }) {
	const downloadFile = () => {
		const transformedData = transformData(data)

		try {
			if (customDownloadFile) {
				return customDownloadFile(transformedData, fileExtension, fileName)
			} else {
				return defaultDownloadFile(transformedData)
			}
		} catch (err) {
			ErrorHandling("Error creating file")
		}
	}

	const defaultDownloadFile = (transformedData) => {
		const element = document.createElement("a")
		const file = new Blob([transformedData], { type: fileType })

		element.href = URL.createObjectURL(file)
		element.download = `${fileName}.${fileExtension}`
		document.body.appendChild(element)
		element.click()
	}

	return (
		<Button disabled={loading} className={`${loading ? "button-secondary" : "button-primary"} m-l-2-theme`} onClick={() => downloadFile()}>
			{loading ? <CircularProgress thickness={5} disableShrink /> : "Download File"}
		</Button>
	)
}
